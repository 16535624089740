import { Controller } from "@hotwired/stimulus"
import * as general from "../general"
import * as helpers from '../helpers'
import * as bootstrap from 'bootstrap'
import { DateRangePicker, Datepicker } from 'vanillajs-datepicker';
import sl from 'vanillajs-datepicker/locales/sl';

export default class extends Controller {

  connect() {
    this.init();
    Object.assign(Datepicker.locales, sl);
  }

  init() {
    this.refreshAll();
    this.initContextMenus();
    this.initTooltips();
  }

  initContextMenus() {
    for(var el of document.querySelectorAll('.right-clickable input')) {
      el.addEventListener('contextmenu', this.showCommentModal.bind(this));
    }
  }

  initTooltips() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  onCommentSave(input) {
    var self = this;
    var data = input.dataset;
    var obj = JSON.parse(input.dataset.obj);
    return function() {
      let json = general.formToObject(document.querySelector('form[name="comment_form"]'));
      var postData = {
        date: obj.date,
        user_id: self.userId(),
        project_id: obj.project_id,
        comment: json.comment
      };

      const success = function(result) {
        obj.comment = result.comment;
        data.obj = JSON.stringify(obj);

        helpers.removeAttribute(input, 'data-bs-toggle');
        helpers.removeAttribute(input, 'data-bs-original-title');
        helpers.removeAttribute(input, 'title');
        helpers.removeAttribute(input, 'data-bs-placement');

        if(obj.comment) {
          helpers.addClass(input, 'has-comment');

          helpers.setAttribute(input, 'data-bs-toggle', 'tooltip');
          helpers.setAttribute(input, 'title', obj.comment);
          helpers.setAttribute(input, 'data-bs-placement', 'top');
        } else {
          helpers.removeClass(input, 'has-comment');
        }
        self.initTooltips();

        window.modal.hide();

        setTimeout(function() {
          input.focus();
        }, 100);
      };
      general.postJson(self.projectEntryUrl(), { project_entry: postData }, success, self.error);
    };
  }

  showCommentModal(event) {
    event.preventDefault();

    const id = general.randomString();
    var input = event.target;
    var data = input.dataset;
    var obj = JSON.parse(data.obj);
    var html = `<form id="${id}" name="comment_form" novalidate><textarea data-row="${data.row}" data-col="${data.col}" data-action="keyup->entry#onCommentKeyUp" class="form-control" name="comment" rows="5">${obj.comment || ''}</textarea></form>`;

    window.modal = general.modal("Komentar", html, this.onCommentSave(input));

    setTimeout(function() {
      var tmp = document.querySelector(`#${id} textarea`);
      if(tmp) {
        tmp.focus();
      }
    }, 100);
  }

  refreshAll() {
    var n = parseInt(this.rowColData().projects);
    for(var col of [...Array(n+10).keys()]) {
      this.refresh(col);
    }
  }

  refresh(col) {
    var n = parseInt(this.rowColData().projects);
    var total;
    if(col == n+1 || col == n+5) {
      total = Array.from(document.querySelectorAll(`[data-col="${col}"]`)).reduce( (sum, el) => sum + (el.checked ? 1 : 0), 0);
    } else if(col == n) {
      // ignore
    } else {
      total = Array.from(document.querySelectorAll(`[data-col="${col}"]`)).reduce( (sum, el) => sum + (numeral(el.value).value() || 0), 0);    
    }
    helpers.setHtml(document.querySelector(`[data-row='total'][data-col='${col}']`), helpers.toNumber(total));
  }

  goTo(event, direction) {
    var parent = event.currentTarget.parentNode;
    var el = parent[`${direction}ElementSibling`];

    helpers.addClass(parent, 'hide');
    helpers.removeClass(el, 'hide');
    helpers.addClass(el, 'active');
    helpers.removeClass(parent, 'active');

    this.refreshTable();
  }

  url() {
    return document.querySelector('#entries').dataset.url;
  }

  projectEntryUrl() {
    return document.querySelector('#entries').dataset.project_entry_url;
  }

  fixUrl() {
    return document.querySelector('#entries').dataset.fix_url; 
  }

  currentUserId() {
    return document.querySelector('#entries').dataset.current_user_id;
  }

  userId() {
    var el = document.querySelector('#user-select');
    return el ? parseInt(el.value) : null;
  }

  editable() {
    var el = document.querySelector('#editable');
    return el ? el.checked : null;
  }

  setEditable(val) {
    var el = document.querySelector('#editable');
    if(el) {
      el.checked = val;
    }
  }

  refreshTable() {
    var self = this;
    var monthData = JSON.parse(document.querySelector('.month.active').dataset.obj);
    var data = {
      month: monthData.month,
      year: monthData.year,
      user_id: this.userId(),
      editable: this.editable()
    };

    var query = new URLSearchParams(data).toString();

    var success = function(result) {
      helpers.setHtml(document.querySelector('#table-holder'), result.html);
      self.init();
    };

    general.getJson(`${this.url()}?${query}`, success);
  }

  prev(event) {
    this.goTo(event, 'previous');
  }

  next(event) {
    this.goTo(event, 'next');
  }

  userChanged(event) {
    this.setEditable( this.currentUserId() == this.userId() );
    this.refreshTable();
  }

  editableChanged(event) {
    this.refreshTable();
  }

  setFocus(row, col) {
    var el = document.querySelector(`[data-row='${row}'][data-col='${col}']`);
    if(el) {
      el.focus();
      if(el.type != 'checkbox') {
        el.setSelectionRange(el.value.length, el.value.length, "forward");
      }
    }
  }

  onCommentKeyUp(event) {
    if( (event.ctrlKey || event.shiftKey) && ( event.code == 'Enter' || event.code == 'NumpadEnter' )) {
      var data = event.currentTarget.dataset;
      var input = document.querySelector(`[data-row='${data.row}'][data-col='${data.col}']`);
      this.onCommentSave(input)();
    }
  }

  onKeyUp(event) {
    var input = event.currentTarget;
    var data = input.dataset;
    var n = (input.value || "").length;
    var sel = input.selectionStart;

    var obj = JSON.parse(data.obj);
    var row = parseInt(data.row);
    var col = parseInt(data.col);
    
    var total = this.rowColData();
    var rows = parseInt(total.rows);
    var cols = parseInt(total.cols);

    if(event.code == 'ArrowUp') {
      this.setFocus( (row - 1 + rows) % rows, col );
    } else if(event.code == 'ArrowDown' || event.code == 'Enter' || event.code == 'NumpadEnter') {
      this.setFocus( (row + 1) % rows, col );
    } else if(event.code == 'ArrowLeft' && (!sel || sel == 0)) {
      this.setFocus( row, (col - 1 + cols) % cols );
    } else if(event.code == 'ArrowRight' && (!sel || sel >= n)) {
      this.setFocus( row, (col + 1) % cols );
    } else if(event.code == 'Insert' && obj.project_id) {
      this.showCommentModal(event);
    }
  }

  setInputValue(date, type, val) {
    var el = document.querySelector(`[name="${date}|${type}"]`);
    if(el) {
      el.value = val;
    }
  }

  toNumber(num) {
    var num_i = parseInt(num);
    return num_i == num ? num_i : num;
  }

  rowColData() {
    return document.querySelector('#data').dataset;
  }

  save(event) {
    var self = this;
    var el = event.currentTarget;
    var d = JSON.parse(el.dataset.obj);
    var val = helpers.getValue(d.type, el, d.old);

    if(!helpers.isValueChanged(d.old, val)) {
      return;
    }

    var url, data;
    if(d.project_id) {
      // PROJECT ENTRY
      url = this.projectEntryUrl();
      var tmp = {
        date: d.date,
        user_id: this.userId(),
        project_id: d.project_id,
        work: val
      };
      data = { project_entry: tmp };
    } else {
      // DATE ENTRY ONLY
      url = this.url();
      var tmp = {
        date: d.date,
        user_id: this.userId()
      };
      tmp[d.field] = val;
      data = { date_entry: tmp };
    }
  
    var success = function(result) {
      self.setInputValue(d.date, "total", helpers.toNumber(result.total));
      self.setInputValue(d.date, "meal", helpers.toNumber(result.meal));
      self.setInputValue(d.date, "arrival_time", result.arrival_time);
      self.setInputValue(d.date, "departure_time", result.departure_time);
      self.refreshAll();

      d.old = el.value;
      el.dataset.obj = JSON.stringify(d);

      if(result.vacation_data) {
        document.querySelector('#vacation').dataset.obj = JSON.stringify(result.vacation_data);
      }

      // general.successSnack('Uspešno shranjeno!');
    };

    var error = function(result) {
      if(el.type == 'checkbox') {
        el.checked = d.old;
      } else {
        el.value = d.old;
      }
    };

    general.postJson(url, data, success, error);
  }

  showVacationData(event) {
    var d = JSON.parse( event.currentTarget.dataset.obj );
    var html = `
      <ul class="list-unstyled">
        <li>Dodeljeno: ${d.given}</li>
        <li>Preneseno: ${d.transferred}</li>
        <li>Porabljeno: ${d.used} (od tega ${d.used_transferred} prenesenega in ${d.used_given} tekočega)</li>
        <li>Na voljo: ${d.available}</li>
      </ul>
    `;
    general.modalWithoutButtons(`Dopust v tekočem letu`, html);
  }

  error(result) {
    helpers.removeAll('.invalid-feedback');
    helpers.removeClassFromAll('.is-invalid', 'is-invalid');
    
    for(let key in result) {
      let input = document.querySelector(`[name="${key}"]`);
      helpers.addClass(input, 'is-invalid');
      helpers.addErrorDivAfter(input, result[key][0]);
    }
  }

  showFixModal(event) {
    var self = this;
    var user = document.querySelector('#user-select option[selected]').innerText;
    const formId = general.randomString();

    const onSave = function() {
      let json = general.formToObject(document.getElementById(formId));
      json.user_id = self.userId();

      // validate
      let errors = {};
      if(!json.from) {
        errors.from = ["Od datum je obvezen"];
      }
      if(!json.to) {
        errors.to = ["Do datum je obvezen"];
      }
      if(Object.keys(errors).length > 0) {
        self.error(errors);
      } else {      
        const success = function(result) {
          self.refreshTable()
          modal.hide();
        };
        general.postJson(self.fixUrl(), { fix: json }, success);
      }
    };

    var modal = general.modal("Popravek", this.fixForm(formId, user), onSave);

    // init range picker
    const rangeEl = document.querySelector(`#${formId} #from_to`);
    const rangepicker = new DateRangePicker(rangeEl, {
      buttonClass: 'btn',
      language: 'sl',
      format: 'dd.mm.yyyy'
    });
  }

  fixForm(formId, name) {
    return `
      <form class="needs-validation" id="${formId}" novalidate>
        <div class="mb-3">
          <input type="text" readonly class="form-control-plaintext" name="user_id" value="${name}">
        </div>
        <div class="input-group mb-3" id="from_to">
          <span class="input-group-text">Od</span>
          <input class="form-control datepicker-input" type="text" name="from">
          <span class="input-group-text">Do</span>
          <input class="form-control datepicker-input rounded-end" type="text" name="to">
        </div>
        <div class="mb-3">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="employed" id="employed_true" value="true" checked="checked">
            <label class="form-check-label" for="employed_true">Zaposlen</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="employed" id="employed_false" value="false">
            <label class="form-check-label" for="employed_false">Zunanji</label>
          </div>
        </div>
      </form>
    `;
  }
}